<template>
    <div>
        <breadcrumbs :pages="[{ title: 'Home' }, { title: 'Authorized', isActive: true  }]"></breadcrumbs>
        <b-row class="justify-content-md-center authorized my-5">
            <b-col md="5" class="mobile-m">
                <Exchange />
            </b-col>
            <b-col md="7">
                <b-row>
                    <b-col md="12" class="c-chart">
                        <div class="bg-gradient2 c-chart-header">
                            <b-navbar toggleable="lg">
                                <b-navbar-toggle
                                        target="nav-collapse"
                                ></b-navbar-toggle>

                                <b-collapse id="nav-collapse" is-nav>
                                    <b-navbar-nav>
                                        <b-nav-item class="block-title">
                                            <span>DAI / USDC Chart</span>
                                        </b-nav-item>
                                    </b-navbar-nav>

                                    <!-- Right aligned nav items -->
                                    <b-navbar-nav class="ml-auto">
                                        <b-navbar-nav class="dow">
                                            <b-badge pill>1 day</b-badge>
                                            <b-badge pill>1 week</b-badge>
                                            <b-badge pill>1 month</b-badge>
                                            <div class="calendar-icon">
                                                <img src="@/assets/img/more.svg" alt="">
                                            </div>
                                        </b-navbar-nav>
                                    </b-navbar-nav>
                                </b-collapse>
                            </b-navbar>
                            <GChart
                                    type="LineChart"
                                    :data="chartData"
                                    :options="chartOptions"
                            />
                        </div>
                    </b-col>
                    <b-col md="12">
                        <div class="pb-5 c-chart-footer">
                            <b-navbar toggleable="lg">
                                <b-navbar-nav>
                                    <b-nav-item class="block-title">
                                        Pool Data
                                    </b-nav-item>
                                </b-navbar-nav>
                            </b-navbar>
                            <div class="px-4">
                                <div class="c-chart-total">
                                    <div class="c-chart-total-item">
                                        <div class="c-chart-total-item-img">
                                            <img src="@/assets/icons/dollar.svg" alt="">
                                        </div>
                                        <div class="c-chart-total-item-data">
                                            <div class="c-chart-total-item-data-title">Total value locked (TVL):</div>
                                            <div class="c-chart-total-item-data-value">${{ total | toNumber | toFixed }}</div>
                                        </div>
                                    </div>
                                    <div class="c-chart-total-item">
                                        <div class="c-chart-total-item-img">
                                            <img src="@/assets/icons/time.svg" alt="">
                                        </div>
                                        <div class="c-chart-total-item-data">
                                            <div class="c-chart-total-item-data-title">24h Volume:</div>
                                            <div class="c-chart-total-item-data-value">$0.00</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-4">
                                <b-row>
                                    <b-col md="8" class="pool-data-table mt-0">
                                        <table class="full-width">
                                            <template v-for="(coin, i) in balanceChartData">
                                                <tr :key="i" v-if="i!==0">
                                                    <td>
                                                        <img src="@/assets/icons/liquid-circle-light.svg" alt="">
                                                        {{ coin[0].toUpperCase() }}
                                                    </td>
                                                    <td class="text-right">${{ coin[2] | toFixed }} <span> ({{ coin[1] | toFixed }}%)</span></td>
                                                </tr>
                                            </template>
                                        </table>
                                    </b-col>
                                    <b-col md="4">
                                        <div class="pool-data-table-item">
                                            <div class="pool-data-table-item-title">Daily Volume</div>
                                            <div class="pool-data-table-item-value">$0.00</div>
                                        </div>
                                        <div class="pool-data-table-item">
                                            <div class="pool-data-table-item-title">Fee</div>
                                            <div class="pool-data-table-item-value">{{ fee }}%</div>
                                        </div>
                                        <div class="pool-data-table-item">
                                            <div class="pool-data-table-item-title">Admin Fee</div>
                                            <div class="pool-data-table-item-value">{{ adminFee }}%</div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Exchange from "../components/Exchange";
import { GChart } from "vue-google-charts";
import filtersMixin from "@/mixins/filters";
import {mapState} from "vuex";

export default {
    name: "Authorized",
    components: {
        Exchange,
        GChart
    },
    mixins: [filtersMixin],
    data: () => ({
        chartData: [
            ["Year", "DAI", { role: "style" }],
            ["2014", 1000, "#ffffff" ],
            ["2014", 800, "#ffffff" ],
            ["2014", 1500, "#ffffff" ],
            ["2014", 200, "#ffffff" ],
            ["2014", 1200, "#ffffff" ],
            ["2014", 400, "#ffffff" ],
            ["2014", 1000, "#ffffff" ],
            ["2015", 1170, "#ffffff" ],
            ["2016", 660,  "#ffffff" ],
            ["2017", 1030, "#ffffff" ]
        ],
        chartOptions: {
            legend: 'none',
            backgroundColor: "transparent",
            vAxis: {
                gridlines: {
                    count: 0,
                },
            },
        }
    }),
    computed: {
        ...mapState([
            'balanceChartData',
            'total',
            'fee',
            'adminFee',
        ])
    }
};
</script>

<style lang="scss">
    @import "src/assets/variables";
    .authorized {
        .approval {
            margin-left: -24px;
            width: calc(100% + 48px);
            border-radius: 0;
        }
    }
</style>
